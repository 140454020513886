import axios from "axios";
import Store from "../services/store";

const baseURL = Store.state.apiURL;

export default {
  async get(url, id) {
    Store.commit("showLoader");
    var getURL = baseURL + url;
    if (id != "" && id != null && id != undefined) {
      getURL = baseURL + url + "/" + id;
    }
    return await axios({ method: "POST", url: getURL })
      .then((res) => {
        Store.commit("hideLoader");
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  async getVocabulary(url, id) {
    Store.commit("showLoader");
    var getURL = baseURL + url;
    if (id != "" && id != null && id != undefined) {
      getURL = baseURL + url + "/" + id;
    }
    return await axios({ method: "GET", url: getURL })
      .then((res) => {
        Store.commit("hideLoader");
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  async getWithFilters(url, model) {
    var apiurl = baseURL + url;

    return await axios
      .post(apiurl, model)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  async getById(url, id, value) {
    var getURL = baseURL + url;
    if (id != "" && id != null && id != undefined) {
      getURL = baseURL + url + "/" + id;
    }
    if (value != "" && value != null && value != undefined) {
      getURL = baseURL + url + "/" + id + "/" + value;
    }
    return await axios
      .get(getURL)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  async post(url, model, id) {
    var apiurl = baseURL + url;
    if (id != null && id != undefined && id != "") {
      apiurl = baseURL + url + "/" + id;
    }
    return await axios
      .post(apiurl, model)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  async delete(url) {
    var deleteURL = baseURL + url;
    return await axios
      .delete(deleteURL)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
};
