<template>
  <div>
    <CCol>
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol>
              <h4>Vocabulary Detail</h4>
            </CCol>
            <CCol col="2">
              <router-link
                id="backToList"
                :to="{ name: 'Vocabulary List' }"
              ></router-link>
              <CButton
                block
                color="dark"
                onclick="document.getElementById('backToList').click()"
                >Back</CButton
              >
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CRow class="pl-2">
            <h5>Vocabulary Details</h5>
          </CRow>
          <CRow class="pt-3">
            <CCol class="col-6">
              <span style="font-weight: bold"> Label: </span>
              {{ item.title }}
            </CCol>
            <CCol class="col-6">
              <span style="font-weight: bold"> Created Date: </span>
              {{ $formatDate(item.createdAt) }}
            </CCol>
          </CRow>
        </CCardBody>

        <CCardBody>
          <div v-for="(data, index) in item.notes" :key="data._id">
            <CRow class="pl-2" style="margin-top: 30px;">
              <h5>{{ index + 1 }}. Notes Details</h5>
            </CRow>

             <CRow class="pt-3">
              <CCol class="col-6">
                <span style="font-weight: bold"> Source Text: </span>
                {{ data.segmentSource }}
              </CCol>
              <CCol class="col-6">
                <span style="font-weight: bold"> Target Text: </span>
                {{ data.segmentTarget }}
              </CCol>
            </CRow>

            <CRow class="pt-3">
              <CCol class="col-6">
                <span style="font-weight: bold"> Translation Strategy: </span>
                {{ data.translationStrategy }}
              </CCol>
              <CCol class="col-6">
                <span style="font-weight: bold"> Translation Direction: </span>
                {{ data.translationDirection }}
              </CCol>
            </CRow>
            <CRow class="pt-3">
              <CCol class="col-6">
                <span style="font-weight: bold"> Category: </span>
                {{ data.translationLabel }}
              </CCol>
              <CCol class="col-6">
                <span style="font-weight: bold"> Source: </span>
                {{ data.SourceOptional }}
              </CCol>
            </CRow>
            <CRow class="pt-3">
              <CCol class="col-6">
                <span style="font-weight: bold"> Example Sentence (Source Text): </span>
                {{ data.sentenceSource }}
              </CCol>
              <CCol class="col-6">
                <span style="font-weight: bold"> Example Sentence (Target Text): </span>
                {{ data.sentenceTarget }}
              </CCol>
              
            </CRow>
            <CRow class="pt-3">
              <CCol class="col-6">
                <span style="font-weight: bold"> Note: </span>
                {{ data.note }}
              </CCol>
              <CCol class="col-6">
                <span style="font-weight: bold"> Revised/Other Version: </span>
                {{ data.revisedVersion }}
              </CCol>
            </CRow>
            <CRow class="pt-3">
              <CCol class="col-6">
                <span style="font-weight: bold"> Interpreting Version: </span>
                {{ data.interpretingVersion }}
              </CCol>
            </CRow>
              <CRow class="pt-3">
              <CCol class="col-6">
                <span style="font-weight: bold"> Title: </span>
                {{ data.title }}
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";
export default {
  name: "VocabularyDetails",
  data() {
    return {
      item: [],
      id: this.$route.params.id,
    };
  },
  created() {
    this.getVocabularyData();
  },
  methods: {
    getVocabularyData() {
      APIService.getById("/vocabularies/getvocabulary", this.id).then(
        (response) => {
          this.item = response.data;
        }
      );
    },
  },
};
</script>
